import { GET_MODULES } from "./actionTypes";
import { GET_MODULES_URL } from "../../configs";
import Axios from "axios";

export const getModules = (lat, lng) => (dispatch) => {
	Axios.post(GET_MODULES_URL, {
		latitude: lat,
		longitude: lng,
	})
	.then((response) => {
		const modules = response.data;
		return dispatch({ type: GET_MODULES, payload: modules });
	})
	.catch(function(error) {
		console.log(error);
	});
};
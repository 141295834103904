import React, { Component } from "react";
import Ink from "react-ink";
import { GET_ADDRESS_FROM_COORDINATES } from "../../../../../configs";
import Axios from "axios";

class GpsSelector extends Component {
	static contextTypes = {
		router: () => null,
	};

	state = {
		gps_loading: false,
		pincode: localStorage.getItem("geoLocality") ? localStorage.getItem("geoLocality") : "",
		formatted_address: localStorage.getItem("formatted_address") ? localStorage.getItem("formatted_address") : "",
	};

	componentDidMount() {
		if (this.props.fetchGpsAutomaticallyAndroid) {
			if (navigator.userAgent === "FoodomaaAndroidWebViewUA") {
				if (window.Android !== "undefined") {
					if (localStorage.getItem("userAlreadySelectedLocation") === null) {
						this.getMyLocation();
					}
				}
			}
		}
	}

	getMyLocation = () => {
		this.startLoading();
		const location = navigator && navigator.geolocation;
		if (location) {
			location.getCurrentPosition(
				(position) => {
					this.reverseLookup(position.coords.latitude, position.coords.longitude);
				},
				(error) => {
					this.setState({ gps_loading: false });
					this.stopLoading();
					console.log(error);
					if (navigator.userAgent !== "FoodomaaAndroidWebViewUA") {
						alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
					}
				},
				{ timeout: 5000 }
			);
		} else {
			this.stopLoading();
		}
	};

	reverseLookup = (lat, lng) => {
		Axios.post(GET_ADDRESS_FROM_COORDINATES, {
			lat: lat,
			lng: lng,
		})
		.then((response) => {
			console.log('reverseLookupLog', response.data);
	
			// Directly access the structured data from the response
			const { pincode, full_address, country, formatted_address } = response.data;

			this.setState({
				formatted_address: formatted_address,
			});
	
			// Use full_address to display the location and pincode directly
			const location = full_address.split(',').slice(0, 2).join(', '); // Get a shorter location if needed
			this.setState({ location: location, pincode: pincode, country: country, dragging: false });

			const myLocation = [
				{
					formatted_address: formatted_address,
					geometry: {
						location: {
							lat: lat,
							lng: lng,
						},
					},
				},
			];
	
			// Update geolocation or other needs with the extracted pincode
			this.handleGeoLocationClick(myLocation, pincode, formatted_address);
		})
		.catch((error) => {
			console.log(error);
		});
	};

	handleGeoLocationClick = (results, pincode, formatted_address) => {
		if (pincode) {
			localStorage.setItem("geoLocality", pincode);
			localStorage.setItem("formatted_address", formatted_address);
		}
		const saveGeoLocation = new Promise((resolve) => {
			localStorage.setItem("geoLocation", JSON.stringify(results[0]));
			resolve("GeoLocation Saved");
		});
		saveGeoLocation.then(() => {
			this.stopLoading();
			if (this.props.fetchGpsAutomaticallyAndroid) {
				//set user location but with business flag (do not redirect to address saving)
				const userSetAddress = {
					lat: JSON.parse(localStorage.getItem("geoLocation")).geometry.location.lat,
					lng: JSON.parse(localStorage.getItem("geoLocation")).geometry.location.lng,
					address: formatted_address,
					house: null,
					tag: null,
					pincode: this.state.pincode,
					businessLocation: true,
				};
				const saveUserSetAddress = new Promise((resolve) => {
					localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
					localStorage.setItem("userAlreadySelectedLocation", "true");
					resolve("Location Saved");
				});
				saveUserSetAddress.then(() => {
					window.location.reload();
				});
			} else {
				this.context.router.history.push("/my-location");
			}
		});
	};

	startLoading = () => {
		document.getElementById("gpsLoadingScreen").classList.remove("hidden");
	};

	stopLoading = () => {
		document.getElementById("gpsLoadingScreen").classList.add("hidden");
	};

	render() {
		return (
			<React.Fragment>
				<div className="R9pSm _10XaM">
					<div stroke="border_Secondary" className="sc-gsFSXq bzBQXP _1DOkS _3otPM" onClick={this.getMyLocation}>
						{/* <div className="_1tqfV icon-sniper-v2" style={{border: '1px solid rgb(226, 226, 231)', color: 'rgb(241, 87, 0)'}} />
						<div className="_1tqfV " style={{border: '1px solid rgb(226, 226, 231)', color: 'rgb(241, 87, 0)'}}>
						<svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c17.7 0 32 14.3 32 32l0 34.7C368.4 80.1 431.9 143.6 445.3 224l34.7 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-34.7 0C431.9 368.4 368.4 431.9 288 445.3l0 34.7c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-34.7C143.6 431.9 80.1 368.4 66.7 288L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l34.7 0C80.1 143.6 143.6 80.1 224 66.7L224 32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" /></svg>
						</div> */}
						<div className="_1tqfV " style={{ border: '1px solid rgb(226, 226, 231)', color: 'rgb(241, 87, 0)' }}>
							<svg width={24} height={24} fill="#D60265" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8l176 0 0 176c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" /></svg>
						</div>
						<div className="eIYtR" tabIndex={0} style={{ gap: '4px' }}>
							<div className="sc-aXZVg icltun _2GWuL">Use Current Location</div>
							<div className="sc-aXZVg cuoJzs" />
						</div>
						<div role="button" aria-label className="_1h3FL _2IHDN" tabIndex={0} style={{ lineHeight: 0 }}>
							<svg width={12} height={13} viewBox="0 0 12 13" fill="none" aria-hidden="true" strokecolor="rgba(2, 6, 12, 0.92)" fillcolor="rgba(2, 6, 12, 0.92)">
								<path fillRule="evenodd" clipRule="evenodd" d="M2.92132 2.30362C3.24538 1.92672 3.81361 1.88388 4.19051 2.20793L8.10108 5.5702C8.11161 5.57926 8.12223 5.58838 8.13292 5.59756C8.30943 5.74917 8.5066 5.91852 8.65206 6.08431C8.82307 6.27919 9.02931 6.58536 9.02931 7.01089C9.02931 7.43643 8.82307 7.7426 8.65207 7.93749C8.50661 8.10327 8.30945 8.27262 8.13294 8.42423C8.12224 8.43342 8.11162 8.44254 8.1011 8.45159L4.22848 11.7813C3.85158 12.1054 3.28334 12.0625 2.95928 11.6856C2.63523 11.3087 2.67806 10.7405 3.05496 10.4164L6.92758 7.08673C6.9591 7.05963 6.98835 7.03447 7.01565 7.0109C6.98835 6.98734 6.9591 6.96218 6.92758 6.93508L3.01701 3.57281C2.64011 3.24875 2.59727 2.68052 2.92132 2.30362Z" fill="rgba(2, 6, 12, 0.92)" fillOpacity="0.92" />
							</svg>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default GpsSelector;

import { GET_INSTACART_CATEGORIES, GET_INSTACART_ITEMS } from "./actionTypes";

const initialState = {
    instacart_categories: [],
    instacart_items: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_INSTACART_CATEGORIES:
            return { ...state, instacart_categories: action.payload };
        case GET_INSTACART_ITEMS:
            return { ...state, instacart_items: action.payload };
        default:
            return state;
    }
}

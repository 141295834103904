import { LOAD_GROCERY_CART, ADD_GROCERY_PRODUCT, REMOVE_GROCERY_PRODUCT } from "./actionTypes";

const initialState = {
    products: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_GROCERY_CART:
            return {
                ...state,
                products: action.payload
            };
        case ADD_GROCERY_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload)
            };
        case REMOVE_GROCERY_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload)
            };
        default:
            return state;
    }
}

import React, { Component } from "react";

import BackWithSearch from "../../Elements/BackWithSearch";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import StoreCoupons from "./StoreCoupons"
import NewStoreCoupons from "./NewStoreCoupons";
import { setFavoriteRest } from "../../../../services/items/actions";

class RestaurantInfo extends Component {
	static contextTypes = {
		router: () => null,
	};

	state = {
		withLinkToRestaurant: false,
		isFavorite: false,
	};

	componentDidMount() {
		this.setState({ withLinkToRestaurant: this.props.withLinkToRestaurant });

		if (this.props.history.location.state && this.props.history.location.state.fromExplorePage) {
			this.setState({ withLinkToRestaurant: this.props.history.location.state.fromExplorePage });
		}

		this.registerScrollEvent();
	}

	componentWillUnmount() {
		this.removeScrollEvent();
	}

	// fixedRestaurantInfo = (hidden) => {
	// 	if (this.child) {
	// 		if (hidden) {
	// 			this.child.heading.classList.add("hidden");
	// 		} else {
	// 			this.child.heading.classList.remove("hidden");
	// 		}
	// 	}
	// };
	fixedRestaurantInfo = (hidden) => {
		if (this.child) {
			if (hidden) {
				document.querySelector(".MenuTopHeader_menuContainer__1xQaU").classList.remove("MenuTopHeader_menuContainerOnScroll__DGhU-");
			} else {
				document.querySelector(".MenuTopHeader_menuContainer__1xQaU").classList.add("MenuTopHeader_menuContainerOnScroll__DGhU-");
			}
		}
	};
	

	registerScrollEvent() {
		window.addEventListener("scroll", this.scrollFunc);
	}
	removeScrollEvent() {
		window.removeEventListener("scroll", this.scrollFunc);
	}
	scrollFunc = () => {
		if (document.documentElement.scrollTop > 55) {
			let hidden = false;
			this.fixedRestaurantInfo(hidden);
		}
		if (document.documentElement.scrollTop < 55) {
			let hidden = true;
			this.fixedRestaurantInfo(hidden);
		}
	};

	setFavoriteRestaurant = () => {
		const { restaurant_info, user } = this.props;
		if (user.success) {
			if (restaurant_info.is_favorited) {
				this.refs.heartIcon.classList.remove("is-active");
			} else {
				this.refs.heartIcon.classList.add("is-active");
			}
			this.props.setFavoriteRest(user.data.auth_token, restaurant_info.id);
		}
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.restaurant_info !== prevState.restaurant_info) {
			return {
				data: nextProps.restaurant_info,
			};
		} else {
			return null;
		}
	}

	render() {
		const { history, restaurant, user } = this.props;
		return (
			<React.Fragment>
				
				<div className="MenuTopHeader_menuContainer__1xQaU" ref={(node) => { this.child = node; }}>
					<button onClick={() => {
							setTimeout(() => {
								this.context.router.history.goBack();
							}, 200);
						}} className="Back_back__1HVhh btn" data-cy="back-button" aria-label="Double tap to go back">
						<svg aria-hidden="true" height={20} width={20} className="sc-bczRLJ bLpMTE">
							<use xlinkHref="/assets/img/various/sprite-14de41e7.svg#arrowBack20" />
						</svg>
					</button>
					{restaurant.length === 0 ? (null):(
					<React.Fragment>
					<div className="MenuTopHeader_content__24MFC" aria-hidden="true">
						<div className="MenuTopHeader_contentTitle__24ryq">
							<div className="sc-gsnTZi fjJtzE">{restaurant.name}</div>
						</div>
						<div className="MenuTopHeader_contentDescription__23Hz9">
							<div className="sc-gsnTZi fmutIM">{restaurant.delivery_time} {localStorage.getItem("homePageMinsText")}</div>
						</div>
					</div>
					<div className="MenuTopHeader_rightNav__alWSF"><button className="MenuTopHeader_rightNavItem__3dysE" data-cy="menu-search-button" aria-label="Search items"><span className="icon-magnifier" /></button></div>
					</React.Fragment>
					)}
				</div>
				<div className="bg-white">
					{/* <BackWithSearch
						ref={(node) => {
							this.child = node;
						}}
						history={history}
						boxshadow={false}
						has_restaurant_info={true}
						restaurant={restaurant}
						disable_search={true}
						homeButton={true}
						shareButton={true}
					/> */}
					{restaurant.length === 0 ? (
						<div style={{ paddingTop:"35px" }}>
						<ContentLoader
							className="custom-gradle-bg"
							height={170}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="70" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="85" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="115" rx="0" ry="0" width="165" height="16" />
						</ContentLoader>
						</div>
					) : (
						<React.Fragment>
							{/* <div className="sc-igHpSv fRGwBn">
								<a href="javascript:void(0)" className="sc-IIEeM golIVG">
									<div className="sc-gsnTZi koeiJQ sc-gGnURB izQyEK">Order Online</div>
									<div className="sc-iuStju cpPRtj" />
								</a>
								<a href="/restaurants/meat-and-eat-maruthi-nagar-btm-btm-5323/dineout" className="sc-IIEeM golIVG">
									<div className="sc-gsnTZi koeiJQ sc-gGnURB izQyEK">Dineout</div>
									<div className="sc-iuStju kvrnWb" />
								</a>
							</div> */}
							<div className="sc-iqGgem cRIpLL">
								<div className="sc-jtcaXd dowonB">
									<div className="sc-kTvvXX kPSDOd" />
									<div className="sc-kngDgl lsyFv">
										<div className="sc-gDeeJ libovB">
											<div className="sc-gsnTZi Ljsdnm">
												<h1 className="sc-gsnTZi eKqnAB">{restaurant.name}</h1>
											</div>
										</div>
									</div>
									<div className="sc-kngDgl WsyFk">
										<div className="sc-dkSuNL KxcDL">
											<div style={{lineHeight: 0}}>
												<svg width={20} height={20} viewBox="0 0 20 20" fill="none" role="img" aria-hidden="true" strokecolor="rgba(2, 6, 12, 0.92)" fillcolor="rgba(2, 6, 12, 0.92)">
												<circle cx={10} cy={10} r={9} fill="url(#StoreRating20_svg__paint0_linear_32982_71567)" />
												<path d="M10.0816 12.865C10.0312 12.8353 9.96876 12.8353 9.91839 12.865L7.31647 14.3968C6.93482 14.6214 6.47106 14.2757 6.57745 13.8458L7.27568 11.0245C7.29055 10.9644 7.26965 10.9012 7.22195 10.8618L4.95521 8.99028C4.60833 8.70388 4.78653 8.14085 5.23502 8.10619L8.23448 7.87442C8.29403 7.86982 8.34612 7.83261 8.36979 7.77777L9.54092 5.06385C9.71462 4.66132 10.2854 4.66132 10.4591 5.06385L11.6302 7.77777C11.6539 7.83261 11.706 7.86982 11.7655 7.87442L14.765 8.10619C15.2135 8.14085 15.3917 8.70388 15.0448 8.99028L12.7781 10.8618C12.7303 10.9012 12.7095 10.9644 12.7243 11.0245L13.4225 13.8458C13.5289 14.2757 13.0652 14.6214 12.6835 14.3968L10.0816 12.865Z" fill="white" />
												<defs>
													<linearGradient id="StoreRating20_svg__paint0_linear_32982_71567" x1={10} y1={1} x2={10} y2={19} gradientUnits="userSpaceOnUse">
													<stop stopColor="#21973B" />
													<stop offset={1} stopColor="#128540" />
													</linearGradient>
												</defs>
												</svg>
											</div>
										</div>
										<div className="sc-gsnTZi fjJtzE">{restaurant.avgRating === "0" ? (<React.Fragment>{restaurant.rating}</React.Fragment>) : (<React.Fragment>{restaurant.avgRating}</React.Fragment>)} (1K+ ratings)</div>
										{/* <div className="sc-gsnTZi fOFiXd sc-cwpsFg houRRg">•</div>
										<div className="sc-gsnTZi fjJtzE"><i className="si si-wallet mr-2" />{" "}
												{localStorage.getItem("currencySymbolAlign") === "left" && (
													<React.Fragment>
														{localStorage.getItem("currencyFormat")}
														{restaurant.price_range}{" "}
													</React.Fragment>
												)}
												{localStorage.getItem("currencySymbolAlign") === "right" && (
													<React.Fragment>
														{restaurant.price_range}
														{localStorage.getItem("currencyFormat")}{" "}
													</React.Fragment>
												)} {localStorage.getItem("homePageForTwoText")}
										</div> */}
									</div>
									<div className="sc-gJwTLC llUxOj">
										<div className="sc-gsnTZi bsNTeZ sc-fxvKuh">{restaurant.description}</div>
										{/* <a href="/city/bangalore/fast-food-cuisine-restaurants">
										</a>
										&nbsp; */}
									</div>
									<div className="sc-iWajrY gEA-dQe">
										{JSON.parse(localStorage.getItem("userSetAddress")) !== null &&(
										<div className="sc-jKDlA-D kQSXlw">
											<div className="sc-KfMfS jdKYxi" />
											<div className="sc-gHLcSH hbnPbU" />
											<div className="sc-KfMfS jdKYxi" />
										</div>
										)}
										<div className={`${JSON.parse(localStorage.getItem("userSetAddress")) !== null ? 'sc-bTMaZy gXTvvr':''}`}>
											<div className="sc-tQuYZ iilwmZ">
												<div className="sc-gsnTZi fCnUbn">Outlet</div>
												<div className="sc-gsnTZi hjXtqt sc-dSuTWQ gEWGOx">{restaurant.city}</div>
												<div className="RestaurantOutletSelector_wrapper__2D3LP"><button className="RestaurantOutletSelector_address__2Tz2x"></button></div>
											</div>
											{JSON.parse(localStorage.getItem("userSetAddress")) !== null && JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
											<div className="sc-cHPgQl Vrafb">
												<div className="sc-gsnTZi fCnUbn">{JSON.parse(localStorage.getItem("userSetAddress")).tag}</div>
												<div className="sc-gsnTZi hjXtqt sc-hZFzCs eQxdxf">
												{JSON.parse(localStorage.getItem("userSetAddress")).businessLocation === true ? (
													<span>
														{JSON.parse(localStorage.getItem("userSetAddress")).address}
													</span>
												) : (
													<span>
														{JSON.parse(localStorage.getItem("userSetAddress")).house !==
														null ? (
															<React.Fragment>
															<span>{JSON.parse(localStorage.getItem("userSetAddress")).house}</span> <span>{JSON.parse(localStorage.getItem("userSetAddress")).address !== null ? ` , ${JSON.parse(localStorage.getItem("userSetAddress")).address}` : null}</span>
															</React.Fragment>
														) : (
															<span>
																{JSON.parse(localStorage.getItem("userSetAddress")).address}
															</span>
														)}
													</span>
												)}
												</div>
											</div>
											):(
											<React.Fragment>
											{JSON.parse(localStorage.getItem("userSetAddress")) !== null &&(
												<div className="sc-cHPgQl Vrafb">
													<div className="sc-gsnTZi fCnUbn">{JSON.parse(localStorage.getItem("userSetAddress")) !== null && JSON.parse(localStorage.getItem("userSetAddress")).city !== null ? JSON.parse(localStorage.getItem("userSetAddress")).city : JSON.parse(localStorage.getItem("userSetAddress")) !== null && JSON.parse(localStorage.getItem("userSetAddress")).locality !== null ? JSON.parse(localStorage.getItem("userSetAddress")) !== null && JSON.parse(localStorage.getItem("userSetAddress")).locality : "Delivery"}</div>
													<div className="sc-gsnTZi hjXtqt sc-hZFzCs eQxdxf">
													{JSON.parse(localStorage.getItem("userSetAddress")).businessLocation === true ? (
                                                        <span>
                                                            {JSON.parse(localStorage.getItem("userSetAddress")).address}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {JSON.parse(localStorage.getItem("userSetAddress")).house !==
                                                            null ? (
                                                                <React.Fragment>
                                                                <span>{JSON.parse(localStorage.getItem("userSetAddress")).house}</span> <span>{JSON.parse(localStorage.getItem("userSetAddress")).address !== null ? ` , ${JSON.parse(localStorage.getItem("userSetAddress")).address}` : null}</span>
                                                                </React.Fragment>
                                                            ) : (
                                                                <span>
                                                                    {JSON.parse(localStorage.getItem("userSetAddress")).address}
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
													</div>
												</div>
											)}
											</React.Fragment>
											)}
										</div>
									</div>
									<hr className="sc-kjEcyX dvxMOf" />
									{/* <ul>
										<li className="sc-fHsOPI ihdIFF">
										<img src="https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_40,h_40/v1648208530/surgecreatives/info" alt="DISTANCE_FEE_NON_FOOD_LM" aria-hidden="true" className="sc-gzzPqb fWNlIS" />
										<div aria-hidden="true" className="sc-gsnTZi hjXtqt"><b>Far (4.3 kms)</b> | Additional delivery fee will apply</div>
										<span className="ScreenReaderOnly_screenReaderOnly___ww-V" tabIndex={0}>&lt;b&gt;Far (4.3 kms)&lt;/b&gt; | Additional delivery fee will apply</span>
										</li>
									</ul> */}
									<div className="sc-gJwTLC llUxOj">
											{/* <div className="sc-gsnTZi hjXtqt sc-hZFzCs">This is test</div> */}
									</div>
									{/* <hr className="sc-cLFqLo erlPXQ" />
									<div className="sc-ewDcJz eXwEtx">
										<div className="sc-ehMyHa jyMWJd">
											<div className="sc-gsnTZi jLPMah">Free delivery on orders above ₹149</div>
										</div>
									</div> */}
								</div>
							</div>
							{/* <Link
								to={"../../stores/" + restaurant.slug}
								className={`store-info-itemspage ${this.state.withLinkToRestaurant ? "" : "no-click"}`}
							>
								<div style={{ paddingTop:"51px" }}>
									<div className="custom-gradle-bg">
										<div className="row">
											<div className={`${restaurant.is_appview === 0 ? "col-8" : "col-10"}`}>
												<div className="ml-5 px-15 mt-5 w-100">
													<h4 className="res-info-name mb-5 text-dark break-word">{restaurant.name}</h4>
													<div className="font-size-sm truncate-text res-info-desc">
														{restaurant.description}
													</div>
													<div className="font-size-sm truncate-text res-info-desc">
														{restaurant.landmark} {restaurant.distance && (",")} {restaurant.distance && restaurant.distance.toFixed(1) (" Kms")}
													</div>
													{restaurant.is_pureveg === 1 && (
														<p className="mb-0">
															<span className="font-size-sm pr-1 res-info-desc">
																{localStorage.getItem("pureVegText")}
															</span>
															<img
																src="/assets/img/various/pure-veg.png"
																alt="PureVeg"
																style={{ width: "20px" }}
															/>
														</p>
													)}
												</div>
											</div>
											{restaurant.is_appview === 0 ? (
											<div className="col-4">
												<div className="mt-5 pb-15 mr-15">
													<button className="RestaurantRatings_wrapper__2294i">
														<span className="RestaurantRatings_avgRating__1TOWY">
															<span className="icon-star"></span><span className="ml-1">{restaurant.avgRating === "0" ? (
															<React.Fragment>
																{restaurant.rating}
															</React.Fragment>
														) : (
															<Link to={"/reviews/" + restaurant.slug} style={{ display: "contents" }} className="yes-click">
															{restaurant.avgRating}
															</Link>
														)}</span>
														</span>
														<span className="RestaurantRatings_totalRatings__3d6Zc">100+ ratings</span>
													</button>
												</div>
											</div>
											):(
												<div className="col-2">
													{user.success && (
														<span className="favStore" onClick={this.setFavoriteRestaurant}>
															<div
																ref="heartIcon"
																className={`heart ${restaurant.is_favorited && "is-active"}`}
															/>
														</span>
													)}
												</div>
											)}
										</div>
										<hr className="mr-15 ml-15 res-ino-division-hr" />
										{restaurant.is_appview === 0 ? (
										<div className="col-12 res-info-time-wrap" style={{ display: 'flex', alignItems: 'center' }}>
											<div className="float-left col-4 p-0 store-distance-block">
												<i className="si si-clock" /> {restaurant.delivery_time}{" "}
												{localStorage.getItem("homePageMinsText")}
											</div>
											<div className="d-flex col-6 p-0 store-avgprice-block text-lowercase">
												<i className="si si-wallet mr-2" />{" "}
												{localStorage.getItem("currencySymbolAlign") === "left" && (
													<React.Fragment>
														{localStorage.getItem("currencyFormat")}
														{restaurant.price_range}{" "}
													</React.Fragment>
												)}
												{localStorage.getItem("currencySymbolAlign") === "right" && (
													<React.Fragment>
														{restaurant.price_range}
														{localStorage.getItem("currencyFormat")}{" "}
													</React.Fragment>
												)}
												{localStorage.getItem("homePageForTwoText")}
											</div>
											<div className="col">
												{user.success && (
													<span className="favStore" onClick={this.setFavoriteRestaurant}>
														<div
															ref="heartIcon"
															className={`new heart ${restaurant.is_favorited && "is-active"}`}
														/>
													</span>
												)}
											</div>
										</div>
										):(
										<div className="container">
											<div className="row">
												<div className="col-4 text-center">
												<span className="icon-star"></span><span className="ml-1 new-time-wrap">{restaurant.avgRating === "0" ? (
													<React.Fragment>
														{restaurant.rating}
													</React.Fragment>
												) : (
													<React.Fragment>
													<Link to={"/reviews/" + restaurant.slug} style={{ display: "contents" }} className="yes-click new-time-wrap"> {restaurant.avgRating}
													</Link>
													</React.Fragment>
												)}</span>
												<p className="mb-1 res-info-new-wraper">100+ ratings</p>
												</div>
												<div className="col-4 text-center">
												<span className="new-time-wrap">{restaurant.delivery_time}{" "}{localStorage.getItem("homePageMinsText")}</span>
												<p className="mb-1 res-info-new-wraper">Delivery Time</p>
												</div>
												<div className="col-4 text-center">
												<span>{localStorage.getItem("currencySymbolAlign") === "left" && (
														<React.Fragment>
															<span className="new-time-wrap">
															{localStorage.getItem("currencyFormat")}
															{restaurant.price_range}{" "}
															</span>
														</React.Fragment>
													)}
													{localStorage.getItem("currencySymbolAlign") === "right" && (
														<React.Fragment>
															<span className="new-time-wrap">
															{restaurant.price_range}
															{localStorage.getItem("currencyFormat")}{" "}
															</span>
														</React.Fragment>
													)}
												</span>
												<p className="mb-1 res-info-new-wraper">{localStorage.getItem("homePageForTwoText")}</p>
												</div>
											</div>
										</div>
										)}
										<hr className="mr-15 ml-15 res-ino-division-hr" />
									</div>
								</div>
							</Link> */}
							{/* {user.success && (
								<span onClick={this.setFavoriteRestaurant}>
									<div
										ref="heartIcon"
										className={`heart ${restaurant.is_favorited && "is-active"}`}
									/>
								</span>
							)} */}
						</React.Fragment>
					)}
				</div>
				{/* <StoreCoupons coupons={restaurant.coupons}/> */}
				{/* <NewStoreCoupons coupons={restaurant.coupons}/> */}
				{restaurant.custom_message !== "<p><br></p>" &&
					restaurant.custom_message !== "null" &&
					(restaurant.custom_message !== "" && (
						<div
							style={{
								position: "relative",
								background: "#fff",
							}}
							dangerouslySetInnerHTML={{
								__html: restaurant.custom_message,
							}}
						/>
					))}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	restaurant_info: state.items.restaurant_info,
	user: state.user.user,
});

export default connect(
	mapStateToProps,
	{ setFavoriteRest }
)(RestaurantInfo);

import React, { Component } from 'react';
import ContentLoader from "react-content-loader";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class NewStoreCoupons extends Component {
  render() {
    const slides = [
        { 
          data: { id: 1, image: 'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_96,h_96/offers/generic', title: 'Flat ₹150 Off', description: 'USE AMEX150'},
          url: 'https://example.com/page1' 
        },
        { 
          data: { id: 2, image: 'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_96,h_96/rng/md/ads/production/15fb1cfe885005447dc8375e7970600f', title: '40% Off Upto ₹80', description: 'USE TRYNEW'},
          url: 'https://example.com/page2' 
        },
        { 
          data: { id: 3, image: 'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_96,h_96/rng/md/ads/production/7f31c9e47ddd7e6a3c16d21553c0ca60', title: '15% Off Upto ₹300', description: 'USE CITIFOODIE'},
          url: 'https://example.com/page3' 
        },
        { 
          data: { id: 4, image: 'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_96,h_96/rng/md/ads/production/59ef5e58052532ad85a185bf516eccce', title: 'Flat ₹30 Off', description: 'USE SIMPL30'},
          url: 'https://example.com/page3' 
        }
    ];      
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 464, min: 300 },
            items: 1,
            partialVisibilityGutter: 0
        },
    };
    return (
      <React.Fragment>
        <div className="sc-ESujJ fDFdvu">
            <div>
                <div className="sc-ZyCDH eWeEej">
                    <div stroke="border_Secondary" className="sc-TRNrF brHcbO sc-jIAOiI bCnlqr">
                        <div className="sc-jOhDuK iSCCUA">
                            <div className="sc-jgbSNz lcbYTZ">
                                <div className="sc-gSAPjG icEyhA">
                                    <Carousel
                                        additionalTransfrom={0}
                                        swipeable={true}
                                        arrows={false}
                                        autoPlay={true}
                                        autoPlaySpeed={5000}
                                        centerMode={false}
                                        className={""}
                                        containerClass={""}
                                        customTransition={"all .5s linear"}
                                        dotListClass={""}
                                        draggable={true}
                                        focusOnSelect={false}
                                        infinite={true}
                                        itemClass={""}
                                        keyBoardControl={true}
                                        minimumTouchDrag={20}
                                        partialVisible={true}
                                        pauseOnHover={true}
                                        renderArrowsWhenDisabled={false}
                                        renderButtonGroupOutside={false}
                                        renderDotsOutside={true}
                                        responsive={responsive}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay={true}
                                        showDots={false}
                                        sliderClass={""}
                                        >
                                        {slides.map((slide) => slide && (
                                            <div className="sc-jfmDQi fkzfZv" key={slide.data.id}>
                                                <div data-testid="offer-card-container-3" className="sc-gFGZVQ gCtHrZ">
                                                    <div className="sc-ckMVTt hVwzQb">
                                                        <div className="sc-fbPSWO iyXpbd"><img className="sc-kDDrLX hBprOq" src={slide.data.image} width={48} height={48} alt={slide.data.title} /></div>
                                                        <div className="sc-GVOUr fAIwDW">
                                                        <div className="sc-gsnTZi bFAIn">{slide.data.title}</div>
                                                        <div className="sc-gsnTZi fEOHXP">{slide.data.description}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="sc-eKszNL jahZkF">
                            <div data-testid="scroll-counter-indicator" className="sc-gsnTZi gBqzpc">4/4</div>
                            <div className="sc-olbas gceUJO">
                                <div className="sc-hiMGwR grvmxD">
                                    <span className="sc-ehmTmK eVwAlW" />
                                    <div className="sc-lbOyJj dzdgWc" />
                                </div>
                                <div className="sc-hiMGwR grvmxD">
                                    <span className="sc-ehmTmK eVwAlW" />
                                    <div className="sc-lbOyJj dzdgWc" />
                                </div>
                                <div className="sc-hiMGwR grvmxD">
                                    <span className="sc-ehmTmK kBLeaG" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewStoreCoupons;

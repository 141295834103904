import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import './newfooter.css'

class NewFooter extends Component {
    state = {
		active_nearme: false,
		active_food: false,
		active_nexday: false,
		active_explore: false,
		active_cart: false,
		active_account: false,
		active_alerts: false,
		unread_alerts_count: null,
	};

	componentDidMount() {
		if (this.props.active_nearme === true) {
			this.setState({ active_nearme: true });
		}
		if (this.props.active_food === true) {
			this.setState({ active_food: true });
		}
		if (this.props.active_nexday === true) {
			this.setState({ active_nexday: true });
		}
		if (this.props.active_explore === true) {
			this.setState({ active_explore: true });
		}
		if (this.props.active_cart === true) {
			this.setState({ active_cart: true });
		}
		if (this.props.active_account === true) {
			this.setState({ active_account: true });
		}
		if (this.props.active_alerts === true) {
			this.setState({ active_alerts: true });
		}
	}
    
    render() {
		return (
			<React.Fragment>
                <div className="_3JaG6">
                    <div className="styles_container__2zNtT">
                        <NavLink to="/" className={`btn styles_navItem__1wod5 ${this.state.active_nearme ? 'styles_navItemSelected__1PNh3' : ''}`}>
                            <span className={`styles_icon__25etV icon-home ${this.state.active_nearme ? 'styles_navItemSelected__1PNh3' : ''}`} />
                            <span className={`styles_icon__25etV styles_iconSelected__tDEZK ${this.state.active_nearme ? 'styles_navItemSelected__1PNh3' : ''}`} />
                            <span>Home</span>
                        </NavLink>
                        <NavLink to="/food" className={`btn styles_navItem__1wod5 ${this.state.active_food ? 'styles_navItemSelected__1PNh3' : ''}`}>
                            <span className={`styles_icon__25etV icon-food ${this.state.active_food ? 'styles_navItemSelected__1PNh3' : ''} `} />
                            <span className={`styles_icon__25etV icon-food styles_iconSelected__tDEZK ${this.state.active_food ? 'styles_navItemSelected__1PNh3' : ''}`} />
                            <span>Food</span>
                        </NavLink>
                        <NavLink to="/grocery" className={`btn styles_navItem__1wod5 ${this.state.active_nexday ? 'styles_navItemSelected__1PNh3' : ''}`}>
                            <span className={`styles_icon__25etV icon-mart ${this.state.active_nexday ? 'styles_navItemSelected__1PNh3' : ''} `} />
                            <span className={`styles_icon__25etV styles_iconSelected__tDEZK icon-mart ${this.state.active_nexday ? 'styles_navItemSelected__1PNh3' : ''}`} />
                            <span>NextDay</span>
                        </NavLink>
                        {/* <NavLink to="/explore" className={`btn styles_navItem__1wod5 ${this.state.active_explore ? 'styles_navItemSelected__1PNh3' : ''}`}>
                            <span className="styles_icon__25etV icon-SearchInverse-v3" />
                            <span className="styles_icon__25etV styles_iconSelected__tDEZK icon-SearchFilled-v3" />
                            <span>Search</span>
                        </NavLink> */}
						<NavLink to="/cart" className={`btn styles_navItem__1wod5 ${this.state.active_cart ? 'styles_navItemSelected__1PNh3' : ''}`}>
							{/* <i className={`icon-CartFilled-v3 styles_icon__25etV ${this.state.active_cart ? "icon-CartFilled-v3 footer-icon footer-active-links" : "icon-CartInverse-v3 footer-icon"}`} /> <br />
							<span className={this.state.active_cart ? "active-footer-tab" : ""}>
								{this.state.active_cart
									? localStorage.getItem("footerCart")
									: localStorage.getItem("footerCart")}
							</span> */}
							<span className={`styles_icon__25etV icon-CartFilled-v3 ${this.state.active_cart ? 'styles_navItemSelected__1PNh3' : ''} `} />
                            <span className={`styles_icon__25etV styles_iconSelected__tDEZK icon-CartFilled-v3 ${this.state.active_cart ? 'styles_navItemSelected__1PNh3' : ''}`} />
                            <span>Cart</span>
						</NavLink>
                        <NavLink to="/my-account" className={`btn styles_navItem__1wod5 ${this.state.active_account ? 'styles_navItemSelected__1PNh3' : ''}`}>
                            <span className={`styles_icon__25etV icon-userIcon-v3 ${this.state.active_account ? 'styles_navItemSelected__1PNh3' : ''} `} />
                            <span className={`styles_icon__25etV styles_iconSelected__tDEZK icon-userFilled-v3 ${this.state.active_account ? 'styles_navItemSelected__1PNh3' : ''}`} />
                            <span>Account</span>
                        </NavLink>
                    </div>
                </div>
			</React.Fragment>
		);
	}
}

export default NewFooter;

import { combineReducers } from "redux";

import settingsReducer from "./settings/reducer";
import locationsReducer from "./locations/reducer";
import popularLocationReducer from "./popularLocations/reducer";
import promoSliderReducer from "./promoSlider/reducer";
import restaurantCategorySlider from "./restaurantCategorySlider/reducer";
import offersReducer from "./offers/reducer";
import modulesReducer from "./modules/reducer";
import itemsReducer from "./items/reducer";
import cartReducer from "./cart/reducer";
import groceryCartReducer from "./grocerycart/reducer";
import totalReducer from "./total/reducer";
import groceryTotalReducer from "./grocerytotal/reducer";
import couponReducer from "./coupon/reducer";
import userReducer from "./user/reducer";
import pagesReducer from "./pages/reducer";
import searchReducer from "./searchRestaurants/reducer";
import addressesReducer from "./addresses/reducer";
import checkoutReducer from "./checkout/reducer";
import confirmCartReducer from "./confirmCart/reducer";
import ordersReducer from "./orders/reducer";
import paymentGatewayReducer from "./paymentgateways/reducer";
import notificationReducer from "./notification/reducer";
import restaurantReducer from "./restaurant/reducer";
import ratingReducer from "./rating/reducer";
import alertReducer from "./alert/reducer";
import instacartReducer from "./instacart/reducer";

import languageReducer from "./languages/reducer";

import deliveryUserReducer from "./Delivery/user/reducer";
import deliveryOrdersReducer from "./Delivery/orders/reducer";
import deliverySingleOrderReducer from "./Delivery/singleorder/reducer";
import gpsLocationReducer from "./Delivery/gpslocation/reducer";
import acceptToDeliver from "./Delivery/deliveryprogress/reducer";

import helperReducer from "./helper/reducer";

export default combineReducers({
	settings: settingsReducer,
	locations: locationsReducer,
	popular_locations: popularLocationReducer,
	promo_slides: promoSliderReducer,
	restaurant_category_slides: restaurantCategorySlider,
	offers: offersReducer,
	modules: modulesReducer,
	items: itemsReducer,
	total: totalReducer,
	grocery_total: groceryTotalReducer,
	cart: cartReducer,
	grocery_cart: groceryCartReducer,
	coupon: couponReducer,
	user: userReducer,
	pages: pagesReducer,
	searches: searchReducer,
	addresses: addressesReducer,
	checkout: checkoutReducer,
	confirmCart: confirmCartReducer,
	orders: ordersReducer,
	paymentgateways: paymentGatewayReducer,
	delivery_user: deliveryUserReducer,
	delivery_orders: deliveryOrdersReducer,
	single_delivery_order: deliverySingleOrderReducer,
	gps_location: gpsLocationReducer,
	accepted_order: acceptToDeliver,
	notification_token: notificationReducer,
	restaurant: restaurantReducer,
	languages: languageReducer,
	rating: ratingReducer,
	alert: alertReducer,
	instacart: instacartReducer,
	helper: helperReducer,
});
